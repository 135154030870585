import React from 'react';
import { Link } from 'gatsby';

// Layout Imports
import Default from '../layouts/Default/Default';
import SEO from '../layouts/SEO/SEO';

// Component Imports
import PageHeader from '../components/shared/PageHeader/PageHeader';
import Section from '../components/shared/Section/Section';
import Card from '../components/shared/Card/Card';
import ImageWithContent from '../components/shared/ImageWithContent/ImageWithContent';

// Image Assets
import Avatar from '../assets/images/content/youtube-avatar.jpg';
import Channel from '../assets/images/content/youtube-channel.png';
import Code from '../assets/images/icons/code.svg';
import Voice from '../assets/images/icons/microphone.svg';
import Recording from '../assets/images/icons/video.svg';

const Services = (props) => {
  return (
    <Default
      location={props.location}
      title='Services'
    >
      <SEO
        title='Services'
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <PageHeader
        heading='Services'
        description={<>Collaborations, Sponsorships, Brand Deals, Event Coverage and even Web Development services are available! If you want to learn more about any of these opportunities or how to engage with the ParkyGames brand please send us a message on the <Link className='link' to='/contact'>Contact</Link> page</>}
      />

      <Section className='section--chevron section--white section--blue-after' heading='Sponsorships' accent>
        <p className='section__description'>Are you interested in getting your products or services exposed to a large and growing audience? Engaging with the ParkyGames online community couldn't be easier! We offer several opportunities...</p>

        <ImageWithContent
          className='m-bottom--large'
          image={Avatar}
          imageDescription='ParkyGames YouTube avatar'
        >
          <ul className='list'>
            <li className='list-item'>Exclusive product sponsorships for the ParkyGames YouTube Channel;</li>
            <li className='list-item'>Videos tailored to show off your games and products;</li>
            <li className='list-item'>Social media advertising for your products and services;</li>
            <li className='list-item'>Sponsored video giveaways or unboxings;</li>
            <li className='list-item'>Product placement across all YouTube and media platforms;</li>
            <li className='list-item'>Participation in, and video or social media coverage, of events.</li>
          </ul>
        </ImageWithContent>

        <p className='paragraph center p-top--large p-bottom--large'>We are dedicated to providing high-quality video and social media coverage for your products/services, with a quick and easy process, allowing you to create a mutually-beneficial partnership with the ParkyGames brand. Please send us a message on the <Link className='link' to='/contact'>Contact</Link> page for more information.</p>
      </Section>

      <Section className='section--chevron section--grey-after section--blue' heading="Collaborations">
        <p className='section__description'>I'm always looking for other Youtubers to take part in collaborative videos with me, or to create a mutually beneficial partnership with. In the past, I've created some very popular video series and successful partnerships with other YouTube channels. Here are some basic expectations...</p>
        
        <ImageWithContent
          className='m-bottom--large'
          image={Channel}
          imageDescription='ParkyGames YouTube channel'
        >
          <ul className='list white'>
            <li className='list-item white'>Your channel must have a substantial or similar subscriber count to my channel;</li>
            <li className='list-item white'>Your channel must receive a sizeable and regular amount of views;</li>
            <li className='list-item white'>The video(s) idea(s) must be achievable and result in a high-quality product;</li>
            <li className='list-item white'>Your schedule is flexible to suit both of our needs;</li>
            <li className='list-item white'>If the video(s) is posted on your channel, a link to my channel must be present in the video description;</li>
            <li className='list-item white'>You have high quality recording equipment (audio and visual), and have a good understanding of video editing;</li>
            <li className='list-item white'>You must have a good internet connection (to avoid repeated dropouts from recording sessions and further issues).</li>
          </ul>
        </ImageWithContent>

        <p className='paragraph center p-top--large p-bottom--large white'>There's opportunity to feature your channel on the 'Featured Channels' list of my channel. This follows the expectations above, and you will be required to reciprocate in turn.</p>
      </Section>

      <Section variant='grey' heading="Other services">
        <p className='section__description'>Here are a few other services we can provide...</p>

        <Card>
          <Card.Item
            imageSource={Recording}
            internal='contact'
            heading='Event coverage'
            description='Press coverage of your event or meetup'
          />

          <Card.Item
            imageSource={Voice}
            internal='contact'
            heading='Voice acting'
            description='Use my voice for your application or branding'
          />

          <Card.Item
            imageSource={Code}
            external='http://www.jpdevelopment.co.uk'
            heading='Website development'
            description='Professional bespoke website development services'
            ctaText='Learn more'
          />
        </Card>
      </Section>
    </Default>
  );
};

export default Services;