import React from 'react';
import PropTypes from 'prop-types';

// Style imports
import './ImageWithContent.scss';

const ImageWithContent = ({
  image,
  imageDescription,
  children,
  className,
  reverse,
}) => {
  const baseClass = 'image-with-content';
  const reverseClass = reverse ? 'image-with-content--reverse' : '';
  const otherClasses = className ? className : '';
  const classes = [baseClass, reverseClass, otherClasses].filter(Boolean).join(' ');

  return (
    <div className={classes}>
      <div className='image-with-content__image-wrapper'>
        <img className='image-with-content__image' src={image} alt={imageDescription} />
      </div>
      <div className='image-with-content__content'>
        {children}
      </div>
    </div>
  );
};

ImageWithContent.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  imageDescription: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  reverse: PropTypes.bool,
};

export default ImageWithContent;